<template>
  <div class="container">
    <div class="content">
      <div class="sub-head">{{ subTitle }}</div>
      <div class="sub-des">
        {{ subDes }}
      </div>
    </div>
    <div class="contact">
      <div class="con-title">{{ conTitle }}</div>
      <div class="con-des">
        {{ conDes }}<a href="mailto:sadhilanandstudio@gmail.com" >{{ email }}</a>
      <div class="copyright">Copyright©RE PEAK (HK) CO., LIMITED</div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Contact",
  data() {
    return {
      subTitle: "ShareKaro Is A Popular File Sharing App",
      subDes:
        "ShareKaro is a popular file sharing app, fast and no data usage. You can share all kinds of files to Android/iOS/PC/Jio users, including videos, apps, photos, music, etc.",
      conTitle: "Contact Us",
      conDes: "If you meet any problem, please feel free to contact us ",
      email: "sadhilanandstudio@gmail.com",
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "@/assets/styles/function.scss";

.container {
  height: rem(810px);
  background: #ffffff;
}
.content {
  height: rem(593px);
  background: linear-gradient(270deg, #30c7ff 0%, #2f9cf6 100%);
  text-align: center;
  padding: rem(85px 37px);
  .sub-head {
    font-size: rem(40px);
    font-family: HelveticaNeue-Medium, HelveticaNeue;
    font-weight: 500;
    color: #ffffff;
    line-height: rem(56px);
  }
  .sub-des {
    font-size: rem(28px);
    font-family: HelveticaNeue;
    color: #ffffff;
    line-height: rem(40px);
    margin-top: rem(27px);
  }
}
.contact {
  //width: rem(630px);
  height: rem(257px);
  background: #ffffff;
  box-shadow: 0px 6px 50px 0px rgba(0, 0, 0, 0.09);
  border-radius: rem(8px);
  margin: rem(0 45px);
  padding: rem(48px 0);
  position: relative;
  top: rem(-120px);
  .con-title {
    font-size: rem(40px);
    font-family: HelveticaNeue-Medium, HelveticaNeue;
    font-weight: 500;
    color: #222222;
    line-height: rem(56px);
    text-align: center;
  }
  .con-des {
    font-size: rem(28px);
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #757575;
    line-height: rem(40px);
    text-align: center;
    padding-top: rem(25px);
    a {
      color: #2f9cf6;
    }
  }
}
</style>
